<template>
  <nuxt-link :to="localePath('/')" class="footer__logo">
    <nuxt-img
      class="footer__logo__img desktop-only"
      src="/logo.png"
      alt="Radio Italia logo"
    />
    <nuxt-img
      class="footer__logo__img smartphone-only"
      src="/logo.png"
      alt="Radio Italia logo"
      width="100"
    />
  </nuxt-link>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'FooterLogo',
});
</script>

<style lang="scss">
.footer__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem 0;
  border-bottom: 0.0625rem solid #ccc;
  @include pointer;
}
@include from-desktop-min {
  .footer__logo {
    border-bottom: none;
    justify-content: center;
    align-items: flex-start;
    &__img {
      width: 6.25rem;
    }
  }
}
</style>
