<template>
  <div class="footer__social">
    <span>SEGUICI SU</span>
    <a
      class="footer__social__link"
      v-for="({ link, label }, socialIndex) in items.children"
      :key="`footer-social-${socialIndex}`"
      :href="normalizeLink(link)"
      target="_blank"
    >
      <nuxt-img
        width="15"
        height="15"
        class="footer__social__icon"
        :src="`/social/icon-${label && label.toLowerCase()}.svg`"
        alt="social-icon"
      />
    </a>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
// import {} from '@storefront-ui/vue';

export default defineComponent({
  name: 'FooterSocial',
  components: {},
  props: {
    items: {
      type: Object,
      default: () => {},
    },
  },
  setup() {},
  methods: {
    normalizeLink(link) {
      return link
        ? /^https?:\/\//.test(link)
          ? link
          : `https:${/^\/\//.test(link) ? link : `//${link}`}`
        : '/';
    },
  },
});
</script>

<style lang="scss" scoped>
.footer__social {
  display: flex;
  padding: 2.5625rem 1rem;
  span {
    @include text-13x16-regular;
    line-height: 0.725rem;
  }
  &__link {
    padding: 0 0.6rem;
    @include flex-center;
    &:not(:last-child) {
      border-right: 0.0063rem solid #363636;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  @include for-mobile {
    align-items: center;
    justify-content: center;
    padding: 2rem 0 1rem 0;
  }
}
@include from-desktop-min {
  .footer__social {
    padding: 0;
    height: fit-content;
    min-height: 2.5rem;
    align-items: end;
    justify-content: end;
  }
}
</style>
