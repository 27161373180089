<template>
  <div class="footer__newsletter">
    <div class="footer__newsletter__title">NEWSLETTER</div>
    <div class="footer__newsletter__inner">
      <CustomInput
        input-name="footer-newsletter-email"
        input-id="footer-newsletter-email"
        class="footer__newsletter__inner__input"
        :input-placeholder="$device.isDesktop ? $t('Enter your email') : ''"
        @input="handleInputChange"
      />
      <CustomButton
        @click="handleSubscribe"
        class="footer__newsletter__inner__button"
        :disabled="!privacyAccepted"
      >
        {{ $t('Subscribe') }}
      </CustomButton>
    </div>
    <CustomCheckbox
      class="footer__newsletter__checkbox"
      @input="handleCheckboxChange"
    >
      <template #custom-checkbox-label>
        <div class="footer__newsletter__checkbox__label custom-checkbox__label">
          <span class="footer__newsletter__checkbox__label__text">
            {{ $t('I declare that I have read our') }}
          </span>
          <nuxt-link
            :to="privacyPolicyUrl"
            class="footer__newsletter__checkbox__label__link"
          >
            privacy policy
          </nuxt-link>
          <span class="footer__newsletter__checkbox__label__text">
            {{
              $t(
                'and I agree to the processing of my personal data for sending the newsletter.'
              )
            }}
          </span>
        </div>
      </template>
    </CustomCheckbox>
  </div>
</template>

<script>
import { defineComponent, shallowRef } from '@nuxtjs/composition-api';
import { useNewsletter, useCms } from '~/composables';
import CustomInput from '../General/FormElements/CustomInput.vue';
import CustomCheckbox from '../General/FormElements/CustomCheckbox.vue';
import { CustomButton } from '../General/FormElements';

export default defineComponent({
  name: 'FooterNewsletter',
  components: { CustomInput, CustomButton, CustomCheckbox },
  props: {},
  setup() {
    const { privacyPolicyUrl } = useCms();
    const { subscribe } = useNewsletter();
    const privacyAccepted = shallowRef(false);
    const email = shallowRef();

    const handleCheckboxChange = (value) => {
      privacyAccepted.value = value;
    };

    const handleInputChange = (value) => {
      email.value = value;
    };

    const handleSubscribe = async () => {
      if (privacyAccepted.value && email.value) {
        const subscriptionWasSuccessful = await subscribe(email.value);
        if (subscriptionWasSuccessful) {
          email.value = null;
          privacyAccepted.value = false;
        }
      }
    };

    return {
      privacyAccepted,
      handleCheckboxChange,
      handleSubscribe,
      handleInputChange,
      privacyPolicyUrl,
    };
  },
});
</script>

<style lang="scss">
.footer__newsletter {
  margin: 2.5rem 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  &__inner {
    position: relative;
    &__button {
      position: absolute;
      top: 0;
      right: 0;
      width: 30vw !important;
      max-width: 13.375rem;
      @include for-mobile {
        height: var(--form-element-mobile-height) !important;
      }
    }
    &__input {
      #footer-newsletter-email {
        font-family: var(--ri-font-family);
        font-size: var(--font-size-14);
        letter-spacing: 0.0088rem;
        line-height: var(--line-height-25);
      }
    }
  }
  &__title {
    color: var(--c-white);
    font-family: var(--ri-font-family);
    font-size: var(--font-size-14);
    letter-spacing: 0.0088rem;
    line-height: var(--line-height-25);
    font-weight: 700;
  }
  &__checkbox {
    &__label,
    &__label__text,
    &__label__link {
      color: var(--c-white);
      font-family: var(--ri-font-family);
      font-size: var(--font-size-14);
      letter-spacing: 0.0088rem;
      line-height: var(--line-height-25);
    }
    &__label {
      &__link {
        text-decoration: underline;
        &:hover {
          font-size: var(--font-size-14);
        }
      }
    }
  }
}
@include from-desktop-min {
  .footer__newsletter {
    margin: 0;
    &__inner {
      &__input {
        .custom-input__validator {
          display: block;
          width: 100%;
          #footer-newsletter-email {
            width: 100%;
            font-size: var(--font-size-14);
          }
        }
      }
      &__button {
        width: 11.1vw;
        max-width: 13.375rem;
      }
    }
  }
}
</style>
