<template>
  <div class="footer__menu">
    <div class="footer__menu__title" @click="handleShowAccordion">
      <div class="footer__menu--white footer__menu__title__label">LINK</div>
      <nuxt-img
        class="footer__menu__title__arrow"
        :src="
          showAccordion ? '/arrows/down_grey.svg' : '/arrows/right_grey.svg'
        "
      />
    </div>
    <div
      class="footer__menu__accordion"
      :class="showAccordion && 'footer__menu__accordion--visible'"
    >
      <div
        class="footer__menu__link"
        v-for="({ label, link }, linkIndex) in items.children"
        :key="`footer-menu-links-${linkIndex}`"
      >
        <a
          class="footer__menu--grey"
          :href="localePath(`${link}`)"
          target="_blank"
        >
          {{ label }}
        </a>
        <a
          class="footer__menu--grey"
          :href="localePath('/contattaci')"
          target="_blank"
        >
          Contattaci
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, shallowRef } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'FooterLink',
  components: {},
  props: {
    items: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const showAccordion = shallowRef(false);
    const handleShowAccordion = () => {
      showAccordion.value = !showAccordion.value;
    };
    return {
      showAccordion,
      handleShowAccordion,
    };
  },
});
</script>

<style lang="scss">
.footer__menu {
  font-family: var(--ri-font-family);
  font-size: var(--font-size-14);
  letter-spacing: 0.0088rem;
  line-height: var(--line-height-25);
  padding: 1.25rem 1rem;
  border-bottom: 0.0625rem solid #ccc;
  &__title {
    display: flex;
    justify-content: space-between;
    &__label {
      font-weight: bold;
    }
  }
  &__accordion {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.1rem;
    padding-top: 1.25rem;
    &--visible {
      display: flex;
    }
  }
  &--white {
    color: var(--c-white);
  }
  &--grey {
    color: var(--c-primary-lightest);
    display: block;
  }
}
@include from-desktop-min {
  .footer__menu {
    border-bottom: none;
    padding: 0;
    &__title {
      &__arrow {
        display: none;
      }
    }
    &__accordion {
      display: flex;
    }
  }
}
</style>
