<template>
  <div class="form register-form">
    <ValidationObserver v-slot="{ invalid }" key="sign-up">
      <form class="form" autocomplete="off" @submit.prevent="handleRegister">
        <ValidationProvider v-slot="{ errors }" rules="required|email">
          <SfInput
            v-model="form.email"
            :valid="!errors[0]"
            :error-message="$t(errors[0])"
            name="email"
            :label="$t('Email')"
            class="form__element"
            @change="(e) => handleEmailInput(e)"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <SfInput
            v-model="form.firstname"
            :valid="!errors[0]"
            :error-message="$t(errors[0])"
            name="first-name"
            :label="$t('First Name')"
            class="form__element"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <SfInput
            v-model="form.lastname"
            v-e2e="'login-modal-lastName'"
            :valid="!errors[0]"
            name="last-name"
            :label="$t('Last Name')"
            class="form__element"
            :error-message="$t(errors[0])"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <SfInput
            v-model="form.phone_number"
            :valid="!errors[0]"
            name="phone"
            :label="$t('Telephone')"
            class="form__element"
            :error-message="$t(errors[0])"
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          rules="required|password"
          vid="password"
        >
          <SfInput
            v-model="form.password"
            :valid="!errors[0]"
            :error-message="$t(errors[0])"
            name="password"
            :label="$t('Password')"
            type="password"
            has-show-password
            class="form__element"
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          rules="required|confirmed:password"
        >
          <SfInput
            v-model="form.repassword"
            :valid="!errors[0]"
            :error-message="$t(errors[0])"
            name="re-password"
            :label="$t('Re-enter password')"
            type="password"
            has-show-password
            class="form__element repassword"
          />
        </ValidationProvider>
        <div>{{ $t('Having read the information') }} *</div>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <SfCheckbox
            v-model="termsConsent"
            v-e2e="'login-modal-terms'"
            :valid="!errors[0]"
            :error-message="$t(errors[0])"
            name="login-modal-terms"
            class="form__element"
            required
          >
            <template #label>
              <span class="sf-checkbox__label">
                {{ $t('By clicking "subscribe" you accept our ') }}
                <a :href="privacyPolicyUrl" target="_blank">
                  {{ $t('privacy policy') }}
                </a>
              </span>
            </template>
          </SfCheckbox>
        </ValidationProvider>
        <div>{{ $t('Consent for marketing purposes') }}</div>
        <SfCheckbox
          v-model="marketingConsent"
          v-e2e="'sign-up-newsletter'"
          :label="
            $t(
              'I hereby consent the processing of my personal data for sending offers and communications with marketing purposes.'
            )
          "
          name="signupNewsletter"
          class="form__element marketing-checkbox"
        />
        <div class="error-message" v-if="error.register">
          {{ $t(error.register) }}
        </div>
        <br />
        <CustomButton
          v-e2e="'login-modal-submit'"
          type="submit"
          id="register"
          is-full-width
          class="sf-button--full-width form__button btn-yellow sf-button"
          :disabled="registering || !termsConsent || invalid || !passRegex.test"
        >
          <SfLoader :class="{ loader: registering }" :loading="registering">
            <div>{{ $t('Create an account') }}</div>
          </SfLoader>
        </CustomButton>
      </form>
    </ValidationObserver>
    <div class="action">
      <p class="no-account">{{ $t('or') }}</p>
      <div
        v-e2e="'login-modal-login-to-your-account'"
        class="sf-button-link"
        @click="toggleLoginAndRegisterModal('login')"
      >
        {{ $t('login in to your account') }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  useRouter,
  useContext,
} from '@nuxtjs/composition-api';
import { SfInput, SfCheckbox, SfLoader } from '@storefront-ui/vue';
import { useUser, useUiState, useCms } from '~/composables';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/helpers/customer/regex';
import {
  required,
  email,
  confirmed,
  alpha_num,
  length,
} from 'vee-validate/dist/rules';
import CustomButton from '~/components/General/FormElements/CustomButton.vue';

extend('email', {
  ...email,
  message: 'Email is not valid',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('confirmed', {
  ...confirmed,
  message: 'Le password non coincidono',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value) => customerPasswordRegExp.test(value),
});

extend('alpha_num', {
  ...alpha_num,
  message: 'The field must contain only alphanumeric characters',
});

extend('length', {
  ...length,
  message: (_, arguments_) =>
    `Questo campo deve contenere ${arguments_.length} caratteri`,
});

export default defineComponent({
  name: 'Register',
  components: {
    CustomButton,
    SfInput,
    SfCheckbox,
    SfLoader,
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  setup() {
    const { privacyPolicyUrl } = useCms();
    const { toggleLoginAndRegisterModal } = useUiState();
    const { register, error, registering } = useUser();
    const router = useRouter();
    const { app } = useContext();
    const passRegex = ref({ test: true, error: '' });
    const form = ref({});
    const termsConsent = ref(false);
    const marketingConsent = ref(false);

    const consents = computed(() => [
      {
        consent_type: 'TERMS_AND_CONDITIONS',
        consent_value: termsConsent.value,
      },
      {
        consent_type: 'PRIVACY',
        consent_value: termsConsent.value,
      },
      { consent_type: 'MARKETING', consent_value: marketingConsent.value },
    ]);

    const handleRegister = async () => {
      if (!termsConsent.value) return;
      const user = {
        ...form.value,
        consents: consents.value,
        is_subscribed: marketingConsent.value,
        aggregation_id: form.value.tax_code,
      };
      await register(user);
      if (!error.value?.register) {
        await router.push(`${app.localePath('/my-account')}`);
      }
    };

    const handleEmailInput = (event) => {
      form.value.email = event?.target?._value?.toLowerCase();
    };

    return {
      form,
      consents,
      handleRegister,
      error,
      passRegex,
      termsConsent,
      marketingConsent,
      toggleLoginAndRegisterModal,
      registering,
      handleEmailInput,
      privacyPolicyUrl,
    };
  },
});
</script>

<style lang="scss" scoped>
.register-form {
  .sf-checkbox__label {
    &:hover {
      --checkbox-label-color: var(--c-ri-orange);
    }
  }
}
</style>

<style lang="scss">
.register-form {
  .marketing-checkbox {
    .sf-checkbox__label {
      &:hover {
        --checkbox-label-color: var(--c-ri-orange);
      }
    }
  }
  .sf-checkbox__container {
    .sf-checkbox__checkmark {
      &:hover {
        --checkbox-border-color: unset;
      }
      .sf-icon.color-white {
        --icon-color: unset;
      }
    }
  }
}
</style>
