<template>
  <div
    class="checkout-shipping__existing-addresses__address__container"
    :class="
      selected &&
      'checkout-shipping__existing-addresses__address__container__default'
    "
    @click="$emit('select-address', address)"
  >
    <LoadingDots v-if="countries.length === 0 || loadingCountry" />
    <div v-else class="checkout-shipping__existing-addresses__address">
      <div
        v-for="(addressDetail, index) in beautifiedAddress"
        :key="`checkout-shipping-existing-addresses-address-${index}`"
        class="checkout-shipping__existing-addresses__address__detail"
      >
        <span
          class="checkout-shipping__existing-addresses__address__detail__label"
          >{{ addressDetail[0] }}</span
        >
        <span
          class="checkout-shipping__existing-addresses__address__detail__info"
          >{{ addressDetail[1] }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  useContext,
} from '@nuxtjs/composition-api';
import { LoadingDots } from '~/components/General/FormElements';
import { useCountrySearch } from '@gemini-vsf/composables';

export default defineComponent({
  name: 'AddressBox',
  components: { LoadingDots },
  props: {
    countries: {
      type: Array,
      default: () => [],
    },
    address: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    sharedRefKey: {
      type: String,
      default: 'Step:Shipping-',
    },
  },
  setup(props) {
    const loadingCountry = ref(true);
    const { app } = useContext();
    const { search: searchCountry, country } = useCountrySearch(
      `${props.sharedRefKey}${
        props?.address?.country_code || props?.address?.country?.code
      }`
    );

    onMounted(async () => {
      const countryCode =
        props?.address?.country_code || props?.address?.country?.code;
      if (countryCode) {
        await searchCountry({
          id: countryCode,
        });
      }
      loadingCountry.value = false;
    });

    const beautifiedAddress = computed(() => {
      const { address } = props;
      return [
        [`${app.i18n.t('First Name')}: `, address.firstname],
        [`${app.i18n.t('Last Name')}: `, address.lastname],
        [`${app.i18n.t('Street')}: `, address.street?.join(' ')],
        [`${app.i18n.t('City')}: `, address.city],
        [`${app.i18n.t('Postcode')}: `, address.postcode],
        [
          `${app.i18n.t('Province')}: `,
          country?.value?.available_regions?.find(
            (region) => region.code === address.region.region_code
          )?.name ||
            address.region?.region_code ||
            address.region?.code,
        ],
        [`${app.i18n.t('State')}: `, country.value?.full_name_locale],
        [`${app.i18n.t('Telephone')}: `, address.telephone],
      ].filter((e) => e?.[0] && e[1]);
    });

    return {
      searchCountry,
      loadingCountry,
      country,
      beautifiedAddress,
    };
  },
});
</script>

<style lang="scss" scoped>
.checkout-shipping__existing-addresses__address {
  &__container {
    min-height: 8rem;
    @include default-outline-black;
    &__default {
      outline-width: 0.1875rem;
      color: var(--c-white);
    }
  }
  @include flex-column;
  @include paragraph-regular;
  justify-content: space-between;
  padding: 1rem;
  &__detail {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &__info {
      text-align: right;
    }
    &__label {
      font-weight: bold;
    }
  }
}
</style>
