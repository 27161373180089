<template>
  <div class="category-heading">
    <div class="category-top">
      <SfBreadcrumbs
        class="category-top__breadcrumbs"
        v-if="breadcrumbs && breadcrumbs.length > 0"
        :breadcrumbs="breadcrumbs"
      />
      <h1 class="category-top__name" v-if="categoryData">
        {{ categoryData.name }}
      </h1>
      <h2 class="category-top__description" v-if="categoryDescription">
        {{ categoryDescription }}
        <strong
          @click="isExpanded = !isExpanded"
          style="cursor: pointer"
          v-if="categoryDescription.length > 230"
        >
          {{ isExpanded ? $t('Read less') : $t('Read more') }}
        </strong>
      </h2>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { useCategoryPage } from '~/composables';
import { SfBreadcrumbs } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CategoryHeading',
  components: { SfBreadcrumbs },
  props: {
    categoryId: {
      type: String,
      default: '',
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { categoryData, loadingCategoryData } = useCategoryPage(
      props.categoryId
    );
    const isExpanded = ref(false);
    const categoryDescription = computed(() => {
      if (
        !isExpanded.value &&
        categoryData?.value?.description?.length >= 230
      ) {
        return `${categoryData?.value?.description.slice(0, 230)}...`;
      }
      return categoryData?.value?.description;
    });

    return {
      categoryData,
      loadingCategoryData,
      categoryDescription,
      isExpanded,
    };
  },
});
</script>

<style lang="scss" scoped>
.category-heading {
  position: relative;
  max-width: 100vw;
  min-height: 20rem;
  .category-top {
    @include for-desktop {
      position: relative !important;
      top: 0;
      left: 0;
    }
    &__breadcrumbs {
      padding: 1.25rem;
      --breadcrumbs-list-item-seperator: '>';
      font-family: var(--ri-font-family);
      font-weight: bold;
      font-size: 0.8125rem;
      line-height: 1.5625rem;
      @include for-desktop {
        padding: 1.25rem 0;
      }
      .sf-link.sf-breadcrumbs__breadcrumb {
        @include text-13x16-regular;
        color: var(--c-ri-orange);
      }
      @include from-desktop-min {
        display: block;
      }
    }
    &__name,
    &__description {
      font-family: var(--ri-font-family);
      box-sizing: content-box;
      padding: 1.25rem;
      @include for-desktop {
        padding: 1.25rem 0 2.5rem;
        width: 44rem;
        max-width: 44rem;
      }
    }
    &__name {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 1.5rem;
      @include for-desktop {
        font-size: 2.8125rem;
        line-height: 2.8125rem;
        letter-spacing: -0.028125rem;
      }
    }
    &__description {
      font-size: 0.875rem;
      line-height: 1.4375rem;
      letter-spacing: 0.00875rem;
      font-weight: normal;
      color: var(--c-text);
    }
  }
  &__hero {
    max-width: 100vw;
  }
}
</style>
