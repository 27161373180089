<template>
  <div class="footer__payment">
    <nuxt-img
      v-if="isCart"
      class="footer__payment__icon"
      src="/payment/visa-blue.svg"
      alt="payment-icon-visa"
    />
    <nuxt-img
      v-else
      class="footer__payment__icon"
      src="/payment/visa.svg"
      alt="payment-icon-visa"
    />
    <nuxt-img
      class="footer__payment__icon"
      src="/payment/mastercard.svg"
      alt="payment-icon-mastercard"
    />
    <nuxt-img
      v-if="isCart"
      class="footer__payment__icon"
      src="/payment/paypal-blue.svg"
      alt="payment-icon-paypal"
    />
    <nuxt-img
      v-else
      class="footer__payment__icon"
      src="/payment/paypal.svg"
      alt="payment-icon-paypal"
    />
    <nuxt-img
      class="footer__payment__icon"
      src="/payment/american-express.svg"
      alt="payment-icon-american-express"
    />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'FooterPayment',
  props: {
    items: {
      type: Object,
      default: () => {},
    },
    isCart: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.footer__payment {
  display: flex;
  padding: 2.5625rem 1rem;
  border-bottom: 0.0625rem solid #ccc;
  @include for-mobile {
    align-items: center;
    justify-content: center;
  }
  &__icon {
    padding: 0 1.25rem;
    max-width: calc(100vw / 5);
    &:not(:last-child) {
      border-right: 0.0063rem solid #363636;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
@include from-desktop-min {
  .footer__payment {
    border-bottom: none;
    padding: 0;
    height: 2.5rem;
    &__icon {
      max-width: 5rem;
    }
  }
}
</style>
