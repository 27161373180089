<template>
  <div class="footer__zumbia">
    Sito web gestito da<b>&nbsp;ZUMBIA SRL</b>, Via Ampere 28/30 - 42017
    Novellara (RE) <br />
    Tel. 0522661177 - P.IVA: 02154400366 -
    <a href="mailto:info@zumbia.it"><b>&nbsp;info@zumbia.it</b></a>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
// import {} from '@storefront-ui/vue';

export default defineComponent({
  name: 'FooterZumbia',
});
</script>

<style lang="scss" scoped>
.footer__zumbia {
  padding-top: 1rem;
  @include text-13x16-regular;
  a {
    @include text-13x16-regular;
  }
  @include for-mobile {
    padding: 1rem 0;
    text-align: center;
  }
}
</style>
