<template>
  <div class="checkout-header">
    <div class="checkout-header__content">
      <nuxt-link
        class="checkout-header__content__back"
        :to="localePath('/cart')"
      >
        {{ $t('Back') }}
      </nuxt-link>
      <nuxt-link class="checkout-header__content__logo" :to="localePath('/')">
        <nuxt-img
          class="checkout-header__content__logo__icon"
          src="/logo.png"
          alt="Radio Italia logo"
        />
      </nuxt-link>
      <span class="checkout-header__content__hidden">HIDDEN</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CheckoutHeader',
});
</script>

<style lang="scss" scoped>
.checkout-header {
  @include background-general;
  height: 3.5rem;
  &__content {
    @include paragraph-regular;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    margin: 0 auto;
    padding: 0 1.5rem;
    height: 100%;
    @include desktop-boxed-inner;
    &__back {
      @include pointer;
      @include menu;
      font-weight: var(--font-weight-normal);
      color: var(--c-white);
    }
    &__logo {
      height: 100%;
      display: contents;
      &__icon {
        height: 100%;
      }
    }
    &__hidden {
      visibility: hidden;
    }
    @include from-tablet-min {
      padding: 0 2rem;
    }
  }
}
@include from-desktop-min {
  .checkout-header {
    height: 5rem;
  }
}
</style>
