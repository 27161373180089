<template>
  <div class="footer__legal" :class="layout">
    <nuxt-link
      class="footer__legal__link"
      v-for="({ link, label }, legalIndex) in items.children"
      :key="`footer-legal-${legalIndex}`"
      :to="localePath(link)"
    >
      {{ label }}
    </nuxt-link>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'FooterLegal',
  props: {
    items: {
      type: Object,
      default: () => {},
    },
    layout: {
      type: String,
      default: '',
    },
  },
});
</script>

<style lang="scss" scoped>
.footer__legal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2.5rem 0;
  &__link {
    font-family: var(--ri-font-family);
    color: var(--c-primary-lightest);
    font-size: var(--font-size-13);
    line-height: 1.4375rem;
    letter-spacing: 0.0081rem;
    text-decoration: underline;
  }
  &.checkout {
    padding: 1.875rem 0;
  }
}
@include from-desktop-min {
  .footer__legal {
    justify-content: center;
    flex-direction: row;
    align-items: normal;
    padding: 2.5rem 0 0 0;
    margin-top: 2.5rem;
    border-top: 0.0063rem solid #363636;
    &__link {
      padding: 0 1.25rem;
      border-right: 0.0063rem solid var(--c-primary-lightest);
      text-decoration: none;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: none;
      }
    }
    &.checkout {
      padding: 1.875rem 0;
      margin-top: 0;
    }
  }
}
</style>
