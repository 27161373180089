<template>
  <div class="products">
    <div
      v-if="products && products.length > 0 && !loadingProducts"
      class="products-list-loader"
    >
      <div class="category-products">
        <div class="products-list">
          <CustomProductCard
            v-for="(product, index) in products"
            :key="product.uid"
            :class="{ 'big-product': index === 3 }"
            class="product desktop-product"
            :link="localePath(buildProductUrl(product))"
            :title="getProductTitle(product)"
            :image-alt="getProductImageAlt(product, 'thumbnail')"
            :regular-price="$n(getProductPrice(product).regular, 'currency')"
            :special-price="
              getProductPrice(product).special
                ? $n(getProductPrice(product).special, 'currency')
                : null
            "
            :discount-percentage="calculateDiscountPercentage(product)"
            :product="product"
            :colors="
              getSfColorsFromAttributes(getProductColorAttribute(product))
            "
            :is-big-product-card="index === 3"
          />
        </div>
        <CategoryPagination :category-id="categoryId" />
      </div>
    </div>
    <div v-else-if="loadingProducts" class="products-skeleton">
      <SfSkeleton v-for="n in 12" :key="n" />
    </div>
  </div>
</template>

<script>
import { productGetters } from '@gemini-vsf/composables';
import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { CacheTagPrefix, useCache } from '@gemini/vsf-cache';
import { useUrlHelpers, useWishlist, useCategoryPage } from '~/composables';
import CustomProductCard from '~/components/CustomProductCard.vue';
import { SfSkeleton } from '@storefront-ui/vue';
import { isEmpty } from 'lodash-es';
import {
  getProductColorAttribute,
  getSfColorsFromAttributes,
} from '~/helpers/product/productData';
import CategoryPagination from './CategoryPagination.vue';

export default defineComponent({
  name: 'ProductList',
  components: {
    CustomProductCard,
    SfSkeleton,
    CategoryPagination,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { buildProductUrl } = useUrlHelpers();
    const { itemsInWishlist } = useWishlist();
    const { loadingProducts } = useCategoryPage(props.categoryId);

    const getProductTitle = (product) => {
      return isEmpty(productGetters.getName(product))
        ? productGetters.getShortDescription(product)
        : productGetters.getName(product);
    };

    const calculateDiscountPercentage = (product) => {
      return productGetters.getPrice(product).special
        ? Math.round(
            (1 -
              productGetters.getPrice(product).special /
                productGetters.getPrice(product).regular) *
              100
          )
        : null;
    };

    const getProductId = (grn) => {
      if (typeof grn === 'string' || grn instanceof String) {
        const temporary = grn.split('::');
        if (temporary.length >= 2) {
          return temporary[1];
        }
        return temporary[0];
      }
      return null;
    };
    const { addTags } = useCache();

    useFetch(() => {
      if (!props.products?.length) {
        return;
      }
      const tags = [];
      const skus = props.products.reduce(
        (accumulator, product) => [
          ...accumulator,
          productGetters.getProductSku(product),
        ],
        []
      );
      skus.forEach((grn) => {
        const id = getProductId(grn);
        if (id != null) {
          tags.push({ prefix: CacheTagPrefix.Product, value: id });
        }
      });
      if (skus.length > 0) {
        tags.push(
          { prefix: CacheTagPrefix.Category, value: 'productlist' },
          {
            prefix: CacheTagPrefix.Product,
            value: skus,
          }
        );
        addTags(tags);
      }
    });

    return {
      buildProductUrl,
      getRolloverImage: productGetters.getRolloverImage,
      getProductThumbnailImage: productGetters.getProductThumbnailImage,
      getProductName: productGetters.getName,
      getProductPrice: productGetters.getPrice,
      getProductImageAlt: productGetters.getProductImageAlt,
      calculateDiscountPercentage,
      getProductTitle,
      getProductColorAttribute,
      getSfColorsFromAttributes,
      itemsInWishlist,
      loadingProducts,
    };
  },
});
</script>

<style lang="scss">
.products-skeleton {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include ri-from-desktop {
    grid-template-columns: repeat(4, 1fr);
  }
  gap: 1rem;
  .sf-skeleton {
    height: 18.75rem;
  }
}
</style>

<style lang="scss" scoped>
@keyframes loader {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.products {
  position: relative;
  #scroll-loader {
    height: 50px;
  }
}
@keyframes loader {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.category-products {
  margin: 0 auto;
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.products-list {
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;

  .big-product {
    @include ri-from-landscape {
      grid-column: 3 / span 2;
      grid-row: 1 / span 2;
    }
  }
}
@include ri-from-desktop {
  .products-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@include ri-from-landscape-larger {
  .products-list {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
