<template>
  <div class="category-page__order-by__mobile">
    <SfAccordion open="">
      <SfAccordionItem :header="$t('ORDER BY')">
        <CategoryFilterRow
          v-for="(option, optionIndex) in sortingOptions"
          :key="`category-page__order-by__mobile-${optionIndex}`"
          :label="option.label"
          :selected="option.value === currentSorting"
          @click.native="handleSortChange(option.value)"
        />
      </SfAccordionItem>
    </SfAccordion>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useCategoryPage, useUiState } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { SfAccordion } from '@storefront-ui/vue';
import CategoryFilterRow from './CategoryFilterRow.vue';

export default defineComponent({
  name: 'CategoryOrderByMobile',
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
  components: { SfAccordion, CategoryFilterRow },
  setup(props) {
    const { availableSortingOptions, changeSorting, currentSorting } =
      useCategoryPage(props.categoryId);
    const { isFilterSidebarOpen, toggleFilterSidebar } = useUiState();
    const trans = useI18n();

    const sortingOptions = computed(() =>
      availableSortingOptions.value.map((option) => ({
        ...option,
        label: trans.t(option.label),
      }))
    );

    const handleSortChange = async (sort) => {
      if (isFilterSidebarOpen.value) toggleFilterSidebar(props.categoryId);
      await changeSorting(sort);
    };

    return {
      handleSortChange,
      sortingOptions,
      currentSorting,
    };
  },
});
</script>

<style lang="scss">
.category-page__order-by__mobile {
  .sf-accordion-item__header {
    width: 100% !important;
    justify-content: space-between !important;
    border-bottom: 1px solid var(--c-black) !important;
    padding: 1rem 0 0.75rem 0 !important;
  }
  &__mobile {
    border-bottom: var(--form-element-border);
  }
}
</style>
