<template>
  <div class="footer__info">
    <div class="footer__info__title" @click="handleShowAccordion">
      <div class="footer__info--white footer__info__title__label">INFO</div>
      <nuxt-img
        class="footer__info__title__arrow"
        :src="
          showAccordion ? '/arrows/down_grey.svg' : '/arrows/right_grey.svg'
        "
        alt="Arrow icon"
      />
    </div>
    <div
      class="footer__info__accordion"
      :class="showAccordion && 'footer__info__accordion--visible'"
    >
      <div class="footer__info--white">RADIO ITALIA S.p.A</div>
      <div class="footer__info--grey">Capitale Sociale € 580.000,00 i.v.</div>
      <div>
        <span class="footer__info--white">{{ $t('Registered office') }}</span>
        <br />
        <span class="footer__info--grey">Via Savona 97, 20144 Milano</span>
      </div>
      <div>
        <span class="footer__info--white">{{ $t('VAT number') }}</span>
        <br />
        <span class="footer__info--grey">06832230152</span>
      </div>
      <div>
        <span class="footer__info--white">REA N.</span>
        <br />
        <span class="footer__info--grey">MI - 1125258</span>
      </div>
      <div class="footer__info--white">
        {{ $t('Administrative headquarters') }}
      </div>
      <div class="footer__info--grey">
        Viale Europa 49, 20093 Cologno Monzese (Mi)
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, shallowRef } from '@nuxtjs/composition-api';
// import {} from '@storefront-ui/vue';

export default defineComponent({
  name: 'FooterInfo',
  components: {},
  props: {},
  setup() {
    const showAccordion = shallowRef(false);
    const handleShowAccordion = () => {
      showAccordion.value = !showAccordion.value;
    };
    return {
      showAccordion,
      handleShowAccordion,
    };
  },
});
</script>

<style lang="scss" scoped>
.footer__info {
  font-family: var(--ri-font-family);
  font-size: var(--font-size-14);
  letter-spacing: 0.0088rem;
  line-height: var(--line-height-25);
  padding: 1.25rem 1rem;
  border-bottom: 0.0625rem solid #ccc;
  &__title {
    display: flex;
    justify-content: space-between;
    &__label {
      font-weight: bold;
    }
  }
  &__accordion {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.1rem;
    padding-top: 1.25rem;
    &--visible {
      display: flex;
    }
  }
  &--white {
    color: var(--c-white);
  }
  &--grey {
    color: var(--c-primary-lightest);
  }
}
@include from-desktop-min {
  .footer__info {
    border-bottom: none;
    padding: 0;
    &__accordion {
      display: flex;
    }
    &__title {
      &__arrow {
        display: none;
      }
    }
  }
}
</style>
