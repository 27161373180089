var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form register-form"},[_c('ValidationObserver',{key:"sign-up",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.handleRegister.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Email')},on:{"change":function (e) { return _vm.handleEmailInput(e); }},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"first-name","label":_vm.$t('First Name')},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-lastName'),expression:"'login-modal-lastName'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"name":"last-name","label":_vm.$t('Last Name'),"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"name":"phone","label":_vm.$t('Telephone'),"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"password","label":_vm.$t('Password'),"type":"password","has-show-password":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element repassword",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"re-password","label":_vm.$t('Re-enter password'),"type":"password","has-show-password":""},model:{value:(_vm.form.repassword),callback:function ($$v) {_vm.$set(_vm.form, "repassword", $$v)},expression:"form.repassword"}})]}}],null,true)}),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.$t('Having read the information'))+" *")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-terms'),expression:"'login-modal-terms'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"login-modal-terms","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"sf-checkbox__label"},[_vm._v("\n              "+_vm._s(_vm.$t('By clicking "subscribe" you accept our '))+"\n              "),_c('a',{attrs:{"href":_vm.privacyPolicyUrl,"target":"_blank"}},[_vm._v("\n                "+_vm._s(_vm.$t('privacy policy'))+"\n              ")])])]},proxy:true}],null,true),model:{value:(_vm.termsConsent),callback:function ($$v) {_vm.termsConsent=$$v},expression:"termsConsent"}})]}}],null,true)}),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.$t('Consent for marketing purposes')))]),_vm._v(" "),_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('sign-up-newsletter'),expression:"'sign-up-newsletter'"}],staticClass:"form__element marketing-checkbox",attrs:{"label":_vm.$t(
            'I hereby consent the processing of my personal data for sending offers and communications with marketing purposes.'
          ),"name":"signupNewsletter"},model:{value:(_vm.marketingConsent),callback:function ($$v) {_vm.marketingConsent=$$v},expression:"marketingConsent"}}),_vm._v(" "),(_vm.error.register)?_c('div',{staticClass:"error-message"},[_vm._v("\n        "+_vm._s(_vm.$t(_vm.error.register))+"\n      ")]):_vm._e(),_vm._v(" "),_c('br'),_vm._v(" "),_c('CustomButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-submit'),expression:"'login-modal-submit'"}],staticClass:"sf-button--full-width form__button btn-yellow sf-button",attrs:{"type":"submit","id":"register","is-full-width":"","disabled":_vm.registering || !_vm.termsConsent || invalid || !_vm.passRegex.test}},[_c('SfLoader',{class:{ loader: _vm.registering },attrs:{"loading":_vm.registering}},[_c('div',[_vm._v(_vm._s(_vm.$t('Create an account')))])])],1)],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"action"},[_c('p',{staticClass:"no-account"},[_vm._v(_vm._s(_vm.$t('or')))]),_vm._v(" "),_c('div',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-login-to-your-account'),expression:"'login-modal-login-to-your-account'"}],staticClass:"sf-button-link",on:{"click":function($event){return _vm.toggleLoginAndRegisterModal('login')}}},[_vm._v("\n      "+_vm._s(_vm.$t('login in to your account'))+"\n    ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }