<template>
  <div>
    <span class="category-page__counter__number">
      {{ productsCurrentlyInCategory }}
    </span>
    <span class="category-page__counter__text">
      {{ $t('products found') }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useCategoryPage } from '~/composables';

export default defineComponent({
  name: 'CategoryCounter',
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { productsCurrentlyInCategory } = useCategoryPage(props.categoryId);

    return {
      productsCurrentlyInCategory,
    };
  },
});
</script>

<style lang="scss" scoped>
.category-page__counter {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1.5rem;
  padding: 1rem 0;
  @include text-16x25-regular;
  &__number {
    font-weight: bold;
  }
}
</style>
